import { createQuestCustomerParams, getListQuestParams, getQuestMappingParams, QuestAPI } from './api/quest';

const questApi = new QuestAPI();

export class QuestService {
  getListQuest = async (params: getListQuestParams) => {
    const resp = await questApi.getListQuest(params);
    return resp.data;
  }

  getQuestMapping = async (params: getQuestMappingParams) => {
    const resp = await questApi.getQuestMapping(params);
    return resp.data;
  }

  createQuestCustomer = async (params: createQuestCustomerParams) => {
    const resp = await questApi.createQuestCustomer(params);
    return resp.data;
  }
}