import { LEAGUE_TYPE } from '@/stores/slices/league';
import axios from 'axios';

export interface getListQuestParams {
  customerId: string;
  questDate: string;//"2025-02-01"
}

export interface getQuestMappingParams {
  channelId: number,
  searchText: string,
  limit: number,
  offset: number,
  isInsideShop?: true //บังคับเป็น true
}

export interface createQuestCustomerParams {
  "customerId": number,
  "masterQuestIds": number[],
  "applyWholeSaleOrg": boolean,
  "date": string //"2025-02-01"
}

export class QuestAPI {
  /**
   * list quest หน้า visit
   * list quest ที่เลือกไว้ตามวันที่
   * @param params 
   * @returns 
   */
  getListQuest = async (params: getListQuestParams) => {
    let path = `api/Quests/Customer/${params.customerId}?questDate=${params.questDate}`;
    const body = {
      questDate: params.questDate,
    }
    const res = await axios.post(path, body);
    return res.data;
  };
  /**
   * list quest ทั้งหมดใน channel
   * @param params 
   * @returns 
   */
  getQuestMapping = async (params: getQuestMappingParams) => {
    const data = {
      channelId: `${params.channelId}`,
      searchText: params.searchText,
      limit: `${params.limit}`,
      offset: `${params.offset}`,
      isInsideShop: `true`,
    }
    const querytString = new URLSearchParams(data).toString();
    let path = `api/Quests/Mappings?${querytString}`;
    const res = await axios.get(path);
    return res.data;
  }

  createQuestCustomer = async (params: createQuestCustomerParams) => {
    let path = `api/Quests/Customer`;
    const res = await axios.post(path, params);
    return res.data;
  }

}
