import { getIncensive } from '@/services/league';
import { ServiceStatus } from '@/models/enums/service';
import { mergeObjects } from '@/utils/utils';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Service from '@/services';
import { createQuestCustomerParams, getListQuestParams, getQuestMappingParams } from '@/services/api/quest';
const service = new Service.QuestService();
export enum LEAGUE_TYPE {
  total = 'total',
  sellin = 'sellin',
  marketshare = 'marketshare',
  distribution = 'distribution',
}

export interface LeagueState {
  channel: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
  };
  customer: {
    network: {
      code: ServiceStatus;
      error?: string;
    };
    data?: any;
    ids: number[];
  };
}

const initialState: LeagueState = {
  //quest list for current chennel
  channel: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
  },
  //active quest for current customer on specific date
  customer: {
    network: {
      code: ServiceStatus.idle,
      error: undefined,
    },
    data: undefined,
    ids: [] //array of questmasterId
  },

};
/**
 * @link https://redux-toolkit.js.org/api/createSlice
 */
export const slice = createSlice({
  name: 'league',
  initialState,
  //basic state change
  reducers: {
    reset: () => initialState,
    patch: (state, action) => mergeObjects({ ...state }, action.payload),
  },
  //async trunk state change
  extraReducers(builder) {
    builder.addCase(fetchQuestCustomer.pending, (state, action) => {
      state.customer.network.code = ServiceStatus.loading;
    });
    builder.addCase(fetchQuestCustomer.rejected, (state, action) => {
      state.customer.network.error = action.error.message;
      state.customer.network.code = ServiceStatus.failed;
    });
    builder.addCase(fetchQuestCustomer.fulfilled, (state, action) => {
      state.customer.network.code = ServiceStatus.succeeded;
      state.customer.data = action.payload;
      state.customer.ids = action.payload.map((item: any) => item.masterQuestId);
    });

    builder.addCase(fetchQuestChannel.pending, (state, action) => {
      state.channel.network.code = ServiceStatus.loading;
    });
    builder.addCase(fetchQuestChannel.rejected, (state, action) => {
      state.channel.network.error = action.error.message;
      state.channel.network.code = ServiceStatus.failed;
    });
    builder.addCase(fetchQuestChannel.fulfilled, (state, action) => {
      state.channel.network.code = ServiceStatus.succeeded;
      state.channel.data = action.payload;
    });

    // builder.addCase(addQuest.pending, (state, action) => {
    //   state.channel.network.code = ServiceStatus.loading;
    // });
    // builder.addCase(addQuest.rejected, (state, action) => {
    //   state.channel.network.error = action.error.message;
    //   state.channel.network.code = ServiceStatus.failed;
    // });
    // builder.addCase(addQuest.fulfilled, (state, action) => {
    //   state.channel.network.code = ServiceStatus.succeeded;
    //   state.channel.data = action.payload;
    // });
  },
});

/**ตัวอย่างการรัน aysnc fetch data จาก api
 * @link https://redux-toolkit.js.org/api/createAsyncThunk
 * @param custonParam ค่าที่จะรับข้อมูลมาใช้งาน กำหนดเอง
 */

export const fetchQuestCustomer = createAsyncThunk('getQuestCustomer', async (param: getListQuestParams, thunkAPI) => {
  const resp = await service.getListQuest(param).catch((err: any) => {
    console.error('quest error', err);
    return thunkAPI.rejectWithValue(err.message);
  });
  return resp;

});

export const fetchQuestChannel = createAsyncThunk('getQuestChennel', async (param: getQuestMappingParams, thunkAPI) => {
  const resp = await service.getQuestMapping(param).catch((err: any) => {
    console.error('quest error', err);
    return thunkAPI.rejectWithValue(err.message);
  });
  return resp;

});

export const addQuest = createAsyncThunk('addQuest', async (param: createQuestCustomerParams, thunkAPI) => {
  const resp = await service.createQuestCustomer(param).catch((err: any) => {
    console.error('quest error', err);
    return thunkAPI.rejectWithValue(err.message);
  });
  return resp;

});


export const { reset, patch } = slice.actions;

export default slice.reducer;
