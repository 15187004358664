import { combineReducers, configureStore } from '@reduxjs/toolkit';

import theme, { reset as themeReset } from './slices/theme';
import authorization, { reset as authorizationReset } from './slices/authorization';
import notification, { reset as notificationReset } from './slices/notification';
import overview, { reset as overviewReset } from './slices/overview';
import report, { reset as reportReset } from './slices/report/index';
import reportFilter, { reset as reportFilterReset } from './slices/report/filter';
import reportDashboardABL, { reset as reportDashboardABLReset } from './slices/report/dashboard_abl';
import reportDashboardSale, { reset as reportDashboardSaleReset } from './slices/report/dashboard_sale';
import reportDashboardAssistant, { reset as reportDashboardAssistantReset } from './slices/report/dashboard_assistant';
import reportDashboardMsk, { reset as reportDashboardMskReset } from './slices/report/dashboard_msk';
import reportDashboardCallPlan, { reset as reportDashboardCallPlanReset } from './slices/report/dashboard_call_plan';
import reportDashboardSumCallPlan, { reset as reportDashboardSumCallPlanReset } from './slices/report/dashboard_sum_call_plan';
import reportDashboardPowerBI, { reset as reportDashboardPowerBIReset } from './slices/report/power_bi';
import reportDashboardOntrade, { reset as reportDashboardOntradeReset } from './slices/report/dashboard_ontrade';
import communication, { reset as communicationReset } from './slices/communication';
import customer, { reset as customerReset } from './slices/customer';
import offPlan, { reset as offPlanReset } from './slices/plan/off_plan';
import onPlan, { reset as onPlanReset } from './slices/plan/on_plan';
import approvePlan, { reset as approvePlanReset } from './slices/plan/approve';
import visit, { reset as visitReset } from './slices/plan/visit';
import league, { reset as leagueReset } from './slices/league';
import morningTalk, { reset as morningTalkReset } from './slices/morning_talk';
import weeklyReport, { reset as weeklyReportReset } from './slices/weekly_report';
import businessReview, { reset as businessReviewReset } from './slices/business_review';
import management, { reset as managementReset } from './slices/management';
import managementMap, { reset as managementMapReset } from './slices/management/map';
import managementDetail, { reset as managementDetailReset } from './slices/management/detail';
import managementEmployee, { reset as managementEmployeeReset } from './slices/management/employee';
import reportDashboardBPlus, { reset as reportDashboardBPlusReset } from './slices/report/dashboard_bplus';
import quest, { reset as questReset } from './slices/quest';

export const resets = {
  theme: themeReset,
  authorization: authorizationReset,
  notification: notificationReset,
  overview: overviewReset,
  report: reportReset,
  reportFilter: reportFilterReset,
  reportDashboardABL: reportDashboardABLReset,
  reportDashboardSale: reportDashboardSaleReset,
  reportDashboardAssistant: reportDashboardAssistantReset,
  reportDashboardMsk: reportDashboardMskReset,
  reportDashboardCallPlan: reportDashboardCallPlanReset,
  reportDashboardOntrade:reportDashboardOntradeReset,
  reportDashboardBPlus: reportDashboardBPlusReset,
  communication: communicationReset,
  customer: customerReset,
  offPlan: offPlanReset,
  onPlan: onPlanReset,
  approvePlan: approvePlanReset,
  visit: visitReset,
  league: leagueReset,
  morningTalk: morningTalkReset,
  weeklyReport: weeklyReportReset,
  management:managementReset,
  managementMap:managementMapReset,
  managementDetail:managementDetailReset,
  managementEmployee:managementEmployeeReset,
  businessReview: businessReviewReset,
  reportDashboardPowerBI:reportDashboardPowerBIReset,
  reportDashboardSumCallPlan: reportDashboardSumCallPlanReset,
  quest: questReset,
};

const reducer = combineReducers({
  theme,
  authorization,
  notification,
  overview,
  report,
  reportFilter,
  reportDashboardABL,
  reportDashboardSale,
  reportDashboardAssistant,
  reportDashboardMsk,
  reportDashboardCallPlan,
  reportDashboardPowerBI,
  reportDashboardSumCallPlan,
  reportDashboardOntrade,
  reportDashboardBPlus,
  communication,
  customer,
  offPlan,
  onPlan,
  approvePlan,
  visit,
  league,
  morningTalk,
  weeklyReport,
  management,
  managementMap,
  managementDetail,
  managementEmployee,
  businessReview,
  quest,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
