import axios from 'axios';
import * as qs from 'qs';

import { OfflineCustomer } from './../offline/customer';
const customer = new OfflineCustomer();

import { OfflineLocation } from './../offline/location';
const location = new OfflineLocation();

export class CustomerApi {
  async getCustomers(params: any = {}) {
    let path = `/api/Customer/GetListCustomer?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getDetail(params: any = {}) {
    let path = `/api/Customer/GetCustomer/${params.customerId}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }



  async updateCustomerLocation(data: any) {
    try {
      const res = await axios.put(`/api/Customer/UpdateCustomerLocation`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async getSearchProvinceAddress(params: any = {}) {
    let path = `/api/Customer/SearchProvinceAddress?limit=${params.limit}`;
    try {
      if (window.isOffline) {
        return await location.getProvince();
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getSearchDistrictAddress(params: any = {}) {
    let path = `/api/Customer/SearchDistrictAddress?ABLProvinceCode=${params.provinceCode}&limit=${params.limit}`;
    try {
      if (window.isOffline) {
        return await location.getDistrict(params.provinceCode);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getSearchSubDistrictAddress(params: any = {}) {
    let path = `/api/Customer/SearchSubDistrictAddress?ABLProvinceCode=${params.provinceCode}&ABLDistrictCode=${params.districtCode}&limit=${params.limit}`;
    try {
      if (window.isOffline) {
        return await location.getSubDistrict(params.districtCode);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getCustomerProfileDropdown(params: any = {}) {
    let path = `/api/Customer/GetCustomerProfileDropdown?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return await customer.getCustomerProfileDropdown(params);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getCustomerTierDropdown(params: any = {}) {
    let path = `/api/Customer/GetCustomerTierDropdown?${qs.stringify(params)}`;
    try {
      if (window.isOffline) {
        return await customer.getCustomerTierDropdown(params);
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getAddCustomerDropdown(params: any = {}) {
    let path = `/api/Customer/GetAddCustomerDropdown`;
    try {
      if (window.isOffline) {
        return await customer.getCustLegalPerson();
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async postAddCustomerProfile(data: any) {
    try {
      const res = await axios.post(`/api/Customer/AddCustomerProfile`, data);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async submitCustomerOtherOrg(params: any) {
    try {
      const res = await axios.post(`/api/Customer/SubmitCustomerOtherOrg?${qs.stringify(params)}`);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async putUpdateCustomerName(data: any) {
    try {
      const res = await axios.post(`/api/Customer/UpdateCustomerName?customerId=${data.customerId}`, { customerName: data.customerName });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async putUpdateCustomerTaxNo(data: any) {
    try {
      const res = await axios.post(`/api/Customer/UpdateCustomerTaxNo?customerId=${data.customerId}`, { taxNo: data.taxNo });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async putUpdateCustomerBranch(data: any) {
    try {
      const res = await axios.post(`/api/Customer/UpdateCustomerBranchName?customerId=${data.customerId}`, { branchVatNo: data.branchCode, branchName: data.branchName });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async putUpdateCustomerPhone(data: any) {
    try {
      const res = await axios.post(`/api/Customer/UpdateCustomerContact?customerId=${data.customerId}`, { contact: data.phone });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async putUpdateCustomerShopType(data: any) {
    try {
      const res = await axios.post(`/api/Customer/UpdateCustomerShopType?customerId=${data.customerId}`, { customerMasterShopTypeId: data.customerMasterShopTypeId });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async putUpdateCustomerAddress(data: any) {
    try {
      const res = await axios.post(`/api/Customer/UpdateCustomerAddress?customerId=${data.customerId}`, {
        no: data?.address?.no,
        moo: data?.address?.moo,
        village: data?.address?.village,
        building: data?.address?.building,
        floor: data?.address?.floor,
        room: data?.address?.room,
        soi: data?.address?.soi,
        road: data?.address?.road,
        province: data?.address?.province?.text,
        district: data?.address?.district?.text,
        subDistrict: data?.address?.subDistrict?.text,
        zipCode: data?.address?.zipCode,
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async putUpdateCustomerBu(data: any) {
    try {
      const res = await axios.post(`/api/Customer/UpdateCustomerTierMapping?customerId=${data.customerId}`, {
        customerTierMappingId: data.customerTierMappingId,
        customerMasterAttributeBULevel3Id: data.customerMasterAttributeBULevel3Id,
      });
      return res.data;
    } catch (err) {
      throw err;
    }
  }

  async getCustomerCustomProfile(params: { customerId: number | string }) {
    let path = `/api/Customer/Profiles/${params.customerId}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async getCustomerCustomStatus() {
    let path = `/api/Customer/Profiles/Fields/Status`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.get(path);
        return res.data;
      }
    } catch (error) {
      return undefined;
    }
  }

  async updateCustomerCustomProfile(params: { customerId: number | string, data: any }) {
    let path = `/api/Customer/Profiles/${params.customerId}`;
    try {
      if (window.isOffline) {
        return null;
      } else {
        const res = await axios.post(path, params.data);
        return res.data;
      }
    } catch (error) {
      throw error
    }
  }
}
